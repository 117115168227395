<template>

  <div>               

      
      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          
            <header class="modal-card-head">
              <p class="modal-card-title">Add Feedback</p>
              <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">  

              <ul class="stars">
                <li><font-awesome-icon icon="star" /></li>
                <li><font-awesome-icon icon="star" /></li>
                <li><font-awesome-icon icon="star" /></li>
                <li><font-awesome-icon icon="star" /></li>
                <li><font-awesome-icon icon="star" /></li>
              </ul>

              <div v-if="!feedbackAdded">
                <h3>{{question}}</h3>
                <div class="choices-wrapper">
                  <ul class="choice-cloud">
                    <li v-for="(option, index) in choices" :key="index"><span :class="{selected: option.selected}" @click="updatedSelection(index)">{{option.title}}</span></li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <h3>Thanks for your feedback!</h3>
                <p class="spinner"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp;</p>
              </div>



            </section>
            <footer class="modal-card-foot">
              <button :disabled="selectedOptions.length == 0" class="button is-primary" @click="saveFeedback()">Save Feedback</button>
              <button class="button" @click="close">Cancel</button>
            </footer>        

        </div>
      </div>



  </div>

</template>



<script>

import firebase from 'firebase/app';
import { db } from '@/main.js';
import NProgress from 'nprogress';

export default {

  components: {

  },

  props: {
    question: String,
    choices: Array,
    responseType: Boolean
  },

  mounted() {
    
  },

  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
    selectedOptions() {
      return this.choices.filter((option) => option.selected).map((item) => item.title);
    },
  },

  data: function() {
    return {
      showModal: true,
      feedbackAdded: false,
    }
  },

  methods: {

    close: function() {
      this.showModal = false;
      this.clearSelection();
      let result = {
        answer: 'Feedback cancelled',
        modal: false
      };
      this.$emit('feedbackCancelled', result);
    },

    updatedSelection(index) {
      if (this.responseType) this.clearSelection();
      this.choices[index].selected = !this.choices[index].selected;
    },

    clearSelection() {
      this.choices.forEach((item) => {
        item.selected = false;
      });
    },

    saveFeedback: function() {
      let self = this;
      this.feedbackAdded = true;
      var saveFeedback = firebase.functions().httpsCallable('saveFeedback');
      saveFeedback({
        userName: self.userData.username,
        userID: self.userData.userid,
        selectedOptions: self.selectedOptions,
        question: self.question
      }); 

      setTimeout(() => {
        self.close();
      }, 1500);


    }, 

  }


}

</script> 

<style lang="scss" scoped>

  .fa-spin {
    animation-duration: 0.8s; // or something else
  }
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #0a86e0;
  }
  ul.stars {
    margin-bottom: 12px;
    li {
      display: inline-block;
      color: gold;
      margin: 0 10px;
    }
  }
  .spinner {
    svg {
      font-size: 2rem;
      font-weight: bold;
      color: #0a86e0;
      margin-top: 1rem;
    }
  }
  .choices-wrapper {
    .choice-cloud {
      margin-top: 1em;
      text-align: center;
      li {
        display: inline-block;
        cursor: pointer;
        margin: 0 12px 1.3rem 0;
        span {
          background: #e4e4e4;
          transition: 0.3s;
          display: block;
          padding: 10px 20px;
          font-size: 1rem;
          border-radius: 30px;
          &:hover {
            color: #fff;
            background: #6cc8fb;
          }
          &.selected {
            background: #8AD1F6;
            color: #fff;
          }
        }
      }
    }
  }

</style>
